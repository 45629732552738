import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { TonConnectUIProvider, TonConnectButton, useTonWallet, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import axios from 'axios';
import Confetti from 'react-confetti';
import { CoinContext } from '../../contextdtatashare/CoinContext';
import AsbekAppwriteService from '../../api/AsbekAppwriteService';
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f0f2f5;
`;

const MainButton = styled.button`
  padding: 20px 40px;
  font-size: 24px;
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(0,0,0,0.1);
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 12px 20px rgba(0,0,0,0.15);
  }
`;

const BottomSheet = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50vh;
  background: white;
  border-radius: 24px 24px 0 0;
  padding: 24px;
  box-shadow: 0 -4px 20px rgba(0,0,0,0.1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(${props => props.visible ? '0' : '100%'});
`;

const OverlaySheet = styled(BottomSheet)`
  height: 60vh;
  background: #f8f9fa;
  z-index: 1000;
`;

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 12px 0;
  background: ${props => props.selected ? '#f1f4ff' : '#fff'};
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s;
  border: 2px solid ${props => props.selected ? '#2575fc' : '#eee'};

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 24px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
`;

const ActionButton = styled.button`
  width: 100%;
  padding: 18px;
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
  color: white;
  border: none;
  border-radius: 14px;
  font-size: 18px;
  margin-top: 24px;
  cursor: pointer;
  transition: opacity 0.2s;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const TonLogo = () => (
  <svg width="24" height="24" viewBox="0 0 32 32" fill="none">
    <path d="M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0zm10.865 8.5l-6.8 19.2h-3.04l4-11.36-4.8-7.84h3.04l2.72 5.6 2.72-5.6h3.04z" fill="#0088CC"/>
  </svg>
);

const SuccessMessage = styled.div`
  text-align: center;
  padding: 24px;
  background: white;
  border-radius: 24px;
  box-shadow: 0 8px 24px rgba(0,0,0,0.1);
  margin: 24px;
`;

const Checkmark = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #4BB543;
  margin: 0 auto 24px;
  position: relative;
  
  &:before {
    content: '✓';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 32px;
  }
`;
const appwriteService = new AsbekAppwriteService();
const AppContent = () => {
  const [showSheet, setShowSheet] = useState(false);
  const [showSecondSheet, setShowSecondSheet] = useState(false);
  const [showPurchaseSheet, setShowPurchaseSheet] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [confettiRun, setConfettiRun] = useState(true);
  const wallet = useTonWallet();
  const userFriendlyAddress = useTonAddress(true);
  const [tonConnectUI] = useTonConnectUI();
  const {dailyWithdraw,dailyRank,loadingFriends, friends,
      friendsCount,localClickCount,localCoinValue,setLocalClickCount,setLocalCoinValue,blockId,country,vpn,competitionStartDate,ergaa,incrementRank,theRank,resetClickCount,theasbeksPrice, theCoinValue,theWeeklyClickCount,theClickCount,theDailyClickCount,theMonthlyClickCount,incrementCoinValue,incrementWeeklyClickCount,incrementClickCount,incrementDailyClickCount,incrementMonthlyClickCount,theUser,theLastReset,theUserId,theUsername,theFirstName,theLastName,theInvitedBy,theInvitationLink,theAccountNumber,theAccountName,thePhoneNumber,theLastDailyReset,theLastWeeklyReset,theLastMonthlyReset,theBlock,theClickHistory,theOnline,theLastWeekClickCount,theSessionId,theWallet,theSessionTime
    } = useContext(CoinContext);
  const tg = window.Telegram.WebApp;
  tg.ready();

  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;


  const userId = String(445929610).trim();
  
  const saveCoinsToServer = async (clicks, coinValue) => {
    try {
        
       
        // Fetch current document
        const userDoc = await appwriteService.getDocument(userId);
        const currentCoinValue = parseFloat(userDoc.coinValue, 10) || 0;
        const currentLifetimeClicks = parseInt(userDoc.lifetimeClicks, 10) || 0;
        const currentWeeklyClickCount = parseInt(userDoc.weeklyClickCount, 10) || 0;
        const currentDailyClickCount = parseInt(userDoc.dailyClickCount, 10) || 0;
        // Compute new values
        const newCoinValue = currentCoinValue + parseFloat(coinValue);
        const newLifeTimeClicks = currentLifetimeClicks + parseInt(clicks) || 0;
        const newWeeklyClickCount = currentWeeklyClickCount + parseInt(clicks);
        const newDailyClickCount = currentDailyClickCount + parseInt(clicks);
        // Update the document with the new coin value
        const now = Math.floor(Date.now() / 1000);
        await appwriteService.updateDocument(userId, {
            
            weeklyClickCount: newWeeklyClickCount,
            dailyClickCount: newDailyClickCount,
            
        });
        
        // incrementCoinValue(coinValue)
        incrementWeeklyClickCount(clicks)
        incrementClickCount(clicks)
        incrementDailyClickCount(clicks)
        incrementMonthlyClickCount(clicks)
        // // Clear local storage and reset state
        // localStorage.removeItem(`localClickCount_${userId}`);
        // localStorage.removeItem(`localCoinValue_${userId}`);
        
        // setLocalClickCount(0);
        // setLocalCoinValue(0)
        // const currentTime = Date.now();
        
        // localStorage.setItem('lastServerUpdate', currentTime);
        // setTimeSinceLastUpdate(0);
        // setStatus('Saved successfully', userId);
       
    } catch (error) {
        //console.log('Error updating user data:', error, userId);
    }
};
  const swapCurrencies = async () => {
    const tonAmount = 0.01;
    
    if (tonAmount > 0) {
      const validUntil = Math.floor(Date.now() / 1000) + 60;
      
      try {
        const securingResponse = await axios.get(
          'https://67078ccf4eb31299c3df.appwrite.global/generate-payment-token',
          { timeout: 90000 }
        );

        if (securingResponse.data.status === 'true') {
          const transaction = {
            validUntil,
            messages: [
              {
                address: 'UQC6cS3uwguDjyOl9SrP9gty2BBynjZ70M7dHPtr5N2gA919',
                amount: (tonAmount * 1e9).toString(),
              },
            ],
          };

          const result = await tonConnectUI.sendTransaction(transaction);
          if (result) {
            setShowPurchaseSheet(false);
            setShowSecondSheet(false);
            setShowSheet(false);
            setShowSuccess(true);
            setTimeout(() => setConfettiRun(false), 5000);
          }
          saveCoinsToServer(100, 0);
        }
      } catch (error) {
        console.error('Transaction error:', error);
      }
    }
  };

  return (
    <Container>
      {showSuccess && (
        <>
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            recycle={confettiRun}
            numberOfPieces={confettiRun ? 200 : 0}
            colors={['#6a11cb', '#2575fc', '#4BB543']}
            gravity={0.1}
          />
          <OverlaySheet visible={showSuccess}>
            <CloseButton onClick={() => setShowSuccess(false)}>×</CloseButton>
            <div style={{ textAlign: 'center', marginTop: '40px' }}>
              <Checkmark />
              <h2>Congratulations! 🎉</h2>
              <p style={{ fontSize: '18px', margin: '24px 0' }}>
                Your purchase of 5000 clicks was successful!
              </p>
              <ActionButton onClick={() => setShowSuccess(false)}>
                Done
              </ActionButton>
            </div>
          </OverlaySheet>
        </>
      )}

      <MainButton onClick={() => setShowSheet(true)}>
        Boost Your Rank
      </MainButton>

      <BottomSheet visible={showSheet}>
        <CloseButton onClick={() => setShowSheet(false)}>×</CloseButton>
        <h2>Select Package</h2>
        <ListItem onClick={() => setShowSecondSheet(true)}>
          <div>5000 clicks</div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            0.3 TON <TonLogo />
          </div>
        </ListItem>
      </BottomSheet>

      {showSecondSheet && (
        <OverlaySheet visible={showSecondSheet}>
          <CloseButton onClick={() => setShowSecondSheet(false)}>×</CloseButton>
          <div style={{ textAlign: 'center' }}>
            <h2>5000 clicks</h2>
            <p style={{ fontSize: '20px', margin: '16px 0' }}>
              Boost Your Rank
            </p>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
              0.3 TON <TonLogo />
            </div>
            
            {!wallet ? (
              <TonConnectButton 
                style={{ 
                  marginTop: '24px',
                  borderRadius: '14px',
                  padding: '18px',
                  fontSize: '18px'
                }}
              />
            ) : (
              <ActionButton onClick={() => setShowPurchaseSheet(true)}>
                Make Purchase
              </ActionButton>
            )}
          </div>
        </OverlaySheet>
      )}

      {showPurchaseSheet && (
        <OverlaySheet visible={showPurchaseSheet}>
          <CloseButton onClick={() => setShowPurchaseSheet(false)}>×</CloseButton>
          <div style={{ textAlign: 'center' }}>
            <h2>Purchase Details</h2>
            <div style={{ margin: '24px 0' }}>
              <p>5000 clicks</p>
              <p>Total Cost: 0.3 TON <TonLogo /></p>
            </div>
            <ActionButton onClick={swapCurrencies}>
              Confirm Purchase
            </ActionButton>
          </div>
        </OverlaySheet>
      )}
    </Container>
  );
};

export default function App() {
  return (
    <TonConnectUIProvider manifestUrl="https://asbeks.github.io/tonconnect-manifest.json">
      <AppContent />
    </TonConnectUIProvider>
  );
}