import { EarnContainer } from '../asbek/styles';
import AppwriteService from '../api/scripts.js';
import React, {useContext, useState, useEffect, useRef } from 'react';
import { CoinContext } from '../contextdtatashare/CoinContext';
import styled, { keyframes } from 'styled-components';
import AdsComponent from '../AdsGram';
import Ticket from './ticket';
// import TopClicksOfToday from '../api/TopClicksOfToday';
import AsbekAppwriteService from '../api/AsbekAppwriteService';
import {InfoText,DailyCard, StatsContainer, WhiteFriendsCard,FetchingText, RowContainer, RowLabel, RowValue, WhiteCard, CloseButton,GiveLifeButton, IframeContainer, Modal, ModalContent,  } from '../asbek/earn_style.js';
import {
  APPWRITE_ENDPOINT,
  APPWRITE_PROJECT_ID,
  APPWRITE_DATABASE_ID,
  APPWRITE_COLLECTION_ID,
  TASKS_COLLECTION_ID
} from '../api/config';
// import TopUsers from './topchart';
import TopClicksPage from '../api/TopClicksPage';
import WeeklyPage from '../api/weeklyLive';
import { AirlineSeatLegroomExtraSharp } from '@material-ui/icons';
import SomeComponent from '../contextdtatashare/SomeComponent';
import { getIpInfo } from '../ton/ipInfo';
import AsbeksPrice from './AsbeksPrice';
const formatValue = (value) => {
  if (value >= 1_000_000_000) {
    return `${(value / 1_000_000_000).toFixed(1)}B`;
  }
  if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(1)}M`;
  }
  if (value >= 1_000) {
    return `${(value / 1_000).toFixed(1)}k`;
  }
  return value.toString();
};


const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

// Create a Skeleton component with the loading animation
const Skeleton = styled.div`
  background-color: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  height: 17px;
  width: 100px; /* Adjust the width as needed */
  animation: ${loadingAnimation} 1.2s ease-in-out infinite;
`;
const ImageSkeleton = styled.div`
  background-color: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  animation: ${loadingAnimation} 1.2s ease-in-out infinite;
`;

const SkeletonName = styled(Skeleton)`
  height: 20px;
  width: 100px;
  margin-top: 5px;
`;

const SkeletonCoins = styled(Skeleton)`
  height: 20px;
  width: 50px;
  margin-top: 5px;
`;
const BeautifulBanner = styled.div`
  background: linear-gradient(90deg, #ff6a6a, #ffcc00);
  color: #fff;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const clicksAppwriteService = new AppwriteService();
const appwriteService = new AsbekAppwriteService();
const taskappwriteService = new AsbekAppwriteService(TASKS_COLLECTION_ID);

const EarnSection = () => {
  const [showError, setShowError] = useState(false);
  const tg = window.Telegram.WebApp;
  tg.ready();
  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;

    // Optional story sharing parameters

    // Share the story

    ////console.log('User found:', usertg); // Log user details
    
    
    const userId = String(usertg?.id).trim();

  const [bonusCount, setBonusCount] = useState(0); // Track number of clicks
  const [adShown, setAdShown] = useState(false); // Track if the ad has been shown
  const [currentIntervalIndex, setCurrentIntervalIndex] = useState(0); // Track current interval (5, 3, 1)
  const intervals = [1, 3, 1];
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {dailyWithdraw,dailyRank,loadingFriends, friends,
    friendsCount,localClickCount,localCoinValue,setLocalClickCount,setLocalCoinValue,blockId,country,vpn,competitionStartDate,ergaa,incrementRank,theRank,resetClickCount,theasbeksPrice, theCoinValue,theWeeklyClickCount,theClickCount,theDailyClickCount,theMonthlyClickCount,incrementCoinValue,incrementWeeklyClickCount,incrementClickCount,incrementDailyClickCount,incrementMonthlyClickCount,theUser,theLastReset,theUserId,theUsername,theFirstName,theLastName,theInvitedBy,theInvitationLink,theAccountNumber,theAccountName,thePhoneNumber,theLastDailyReset,theLastWeeklyReset,theLastMonthlyReset,theBlock,theClickHistory,theOnline,theLastWeekClickCount,theSessionId,theWallet,theSessionTime
  } = useContext(CoinContext);
  const [status, setStatus] = useState('');
  const [clicks, setClicks] = useState(0);
  const [showResumeButton, setShowResumeButton] = useState(false); 
  const [countdown, setCountdown] = useState(0);
  
  const [isFetchingCoinValue, setIsFetchingCoinValue] = useState(true);

  const [user, setUser] = useState(null);
  const [coinValue, setCoinValue] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [limitReached, setLimitReached] = useState(false); // State to manage if the limit is reached
  const [dailyClickCount, setDailyClickCount] = useState(null);
  const [weeklyClickCount, setWeeklyClickCount] = useState(null);
  const [monthlyClickCount, setMonthlyClickCount] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [errgaa, setErgaa] = useState([]);
  
  
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const [clickBatch, setClickBatch] = useState(0); // Accumulate clicks
  const [batchTimeout, setBatchTimeout] = useState(null); // Timeout for batch updates
  const [timeSinceLastUpdate, setTimeSinceLastUpdate] = useState(0);
  
  const [timeLeft, setTimeLeft] = useState('');
  const [newStartDate, setNewStartDate] = useState(competitionStartDate); // Hold the new date in state
  const [humanReadableStart, setHumanReadableStart] = useState('');
  const [tapCount, setTapCount] = useState(0);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordInput, setPasswordInput] = useState('');

  const correctPassword = '14899315';
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showClaimButton, setshowClaimButton] = useState(false);
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
      // Show the ad button when bonusCount matches the current interval (5, 3, 1)
      if (bonusCount === intervals[currentIntervalIndex] && !adShown) {
        setAdShown(true); // Show the ad button
      }
    }, [bonusCount, currentIntervalIndex, adShown]); // Watch bonusCount, currentIntervalIndex, and adShown
  
    useEffect(() => {
      // Check if the script is already loaded
      if (window.show_8442826) {
        setScriptLoaded(true);
        setIsLoading(false);
        return;
      }
  
      // Create a new script tag to load the ad script
      const tag = document.createElement('script');
      tag.src = '//doakosteceet.net/act/files/tag.min.js';
      tag.dataset.zone = '8442826';
      tag.dataset.sdk = 'show_8442826';
  
      // Append the script to the document body
      document.body.appendChild(tag);
  
      // Handle when the script is successfully loaded
      tag.onload = () => {
        setScriptLoaded(true);
        setIsLoading(false); // Hide the loading state
        //console.log("Ad script loaded successfully.");
      };
  
      // Handle errors if the script fails to load
      tag.onerror = () => {
        setIsLoading(false);
        console.error("Failed to load ad script.");
       //alert("Failed to load the ad script.");
      };
    }, []); // Empty dependency array ensures this runs only once on mount
  
    const showAd = () => {
      if (scriptLoaded && window.show_8442826) {
        // Call the global function to show the ad
        window.show_8442826().then(() => {
          // Add the function to be executed after the ad is shown

          if (isOnline){
            
              setAdShown(false);
              setShowResumeButton(false);
              // Move to the next interval after the ad is shown
        setCurrentIntervalIndex((prevIndex) => (prevIndex + 1) % intervals.length);
        setBonusCount(0); // Reset bonus count after showing the ad
              // incrementCoinValue(10)
              // incrementWeeklyClickCount(1)
              // incrementClickCount(1)
              // incrementDailyClickCount(1)
              // incrementMonthlyClickCount(1)
              setLocalClickCount((prevCount) => {
                const newClickCount = prevCount + 1;
                localStorage.setItem(`localClickCount_${userId}`, newClickCount); // Save to localStorage on each click
                return newClickCount;
              });
        
              setLocalCoinValue((prevCount) => {
                
                const newCoinValue = prevCount + 50;
                localStorage.setItem(`localCoinValue_${userId}`, newCoinValue);
                return newCoinValue;
              });
        
            
          } else {
            //alert('You are offline. MineCoin actions are disabled.');
          }
        }).catch((error) => {
          console.error("Error while showing ad:", error);
         //alert("An error occurred while showing the ad.");
        });
      } else {
       //alert("Ad script is not loaded yet. Please try again.");
      }
    };

  useEffect(() => {
    // Update online status when the connection status changes
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);

  
  // useEffect(() => {
  //   // Function to get the country
  //   const getCountry = async () => {
  //     try {
  //       const ipInfo = await getIpInfo();
  //       if (ipInfo) {
  //         setCountry(ipInfo.country);
  //         // Show banner if the country is Ethiopia
  //         if (ipInfo.country === 'Ethiopia') {
  //           setShowBanner(true);
  //         } else {
  //           setShowBanner(false); // Hide banner if the country is not Ethiopia
  //         }
  //       }
  //     } catch (error) {
  //       console.error('Error fetching country info:', error.message);
  //     }
  //   };

  //   // Fetch country initially
  //   getCountry();

  //   // Set up polling to fetch country every 30 seconds
  //   const intervalId = setInterval(getCountry, 30000);

  //   // Clean up the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, []);

  const handleTap = () => {
    setTapCount(prevCount => {
      if (prevCount + 1 === 20) { // Trigger password dialog at tap count 2
        setShowPasswordDialog(true);
      }
      return prevCount + 1;
    });
  };

  const handlePasswordSubmit = () => {
    if (passwordInput === correctPassword) {
      setShowPasswordDialog(false);
      setShowBottomSheet(true);
    } else {
     //alert('Incorrect password. Please try again.');
    }
  };

  const handleCloseBottomSheet = () => {
    setShowBottomSheet(false);
  };


  
  const toggleBottomSheet = () => {
    setShowBottomSheet(prev => !prev);
  };

  useEffect(() => {
    const startTimeUTC = new Date(competitionStartDate);  // Convert start time to Date object
    setHumanReadableStart(startTimeUTC.toLocaleString());  // Convert to human-readable format
    setNewStartDate(competitionStartDate)
    const updateTimer = () => {
      const currentTimeUTC = Date.now();
      const difference = startTimeUTC.getTime() - currentTimeUTC;

      if (difference > 0) {
        // Countdown to start
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        setTimeLeft(`Starts in: ${days}d ${hours}h ${minutes}m ${seconds}s`);
      } else {
        // Time since start
        const elapsed = Math.abs(difference);
        const days = Math.floor(elapsed / (1000 * 60 * 60 * 24));
        const hours = Math.floor((elapsed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((elapsed % (1000 * 60)) / 1000);
        setTimeLeft(`Started: ${days}d ${hours}h ${minutes}m ${seconds}s ago`);
      }
    };

    updateTimer(); // Initial call to set the time immediately
    const intervalId = setInterval(updateTimer, 1000);

    return () => clearInterval(intervalId);  
  }, [competitionStartDate]);

  // Function to format seconds into hours, minutes, and seconds
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Format to ensure two digits for hours, minutes, and seconds
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    // Fetch the last server update time from localStorage (saved in milliseconds)
    let lastServerUpdate = localStorage.getItem('lastServerUpdate');
  
    if (lastServerUpdate) {
      lastServerUpdate = parseInt(lastServerUpdate, 10);
      
      // Validate if the lastServerUpdate is a valid number
      if (isNaN(lastServerUpdate)) {
        lastServerUpdate = Date.now(); // Set current time if invalid
        localStorage.setItem('lastServerUpdate', lastServerUpdate);
      }
      
      // Calculate how much time has passed since the last update (in seconds)
      const timeElapsed = Math.floor((Date.now() - lastServerUpdate) / 1000);
      setTimeSinceLastUpdate(timeElapsed);
    } else {
      // If no last server update is found, set the current time
      const currentTime = Date.now();
      localStorage.setItem('lastServerUpdate', currentTime);
      setTimeSinceLastUpdate(0);
    }
  
    // Update the elapsed time every second
    const interval = setInterval(() => {
      let lastUpdateTime = parseInt(localStorage.getItem('lastServerUpdate'), 10);
      
      // Ensure the lastUpdateTime is valid
      if (isNaN(lastUpdateTime)) {
        lastUpdateTime = Date.now();
        localStorage.setItem('lastServerUpdate', lastUpdateTime);
      }
      
      const timeElapsed = Math.floor((Date.now() - lastUpdateTime) / 1000);
      setTimeSinceLastUpdate(timeElapsed);
     
      // Call syncWithServer if 30 seconds have passed
      if (timeElapsed >= 60) {
        setshowClaimButton(true);
      }
    }, 1000);
  
    // Cleanup the interval on unmount
    return () => clearInterval(interval);
  }, []);
  

  // const handleClaim = () => {
  //   setshowClaimButton(false)
  //   syncWithServer();
  // }
  const isProcessing = useRef(false); // Add this at the top of your component

  const handleClaim = async () => {
    if (isProcessing.current) return; // Ignore clicks while processing
    isProcessing.current = true; // Lock further clicks immediately
  
    setshowClaimButton(false); // Update UI state to disable the button
    
    try {
      await syncWithServer(); // Perform server synchronization
    } catch (error) {
      console.error("Error syncing with server:", error);
      setshowClaimButton(true); // Re-enable the button on failure
    } finally {
      isProcessing.current = false; // Unlock the button after process finishes
    }
  };



     // Calculate missed clicks if the app was closed
     useEffect(() => {
      const calculateMissedClicks = () => {
          const now = new Date();
          const storedTime = localStorage.getItem('lastServerUpdate');
          if (storedTime) {
              const lastUpdate = new Date(storedTime);
              const timeDifference = now - lastUpdate;
              const interval = 10000; // Assuming updates should happen every 10 seconds
              const missedUpdates = Math.floor(timeDifference / interval);

              // If there were any missed updates, add them to the click count
              if (missedUpdates > 0) {
                  setClicks((prevClicks) => prevClicks + missedUpdates);
              }
          }
      };

      calculateMissedClicks();
  }, []);
  
    useEffect(() => {
      // Check if userId is available before accessing localStorage
      if (userId) {
        const savedLocalClickCount = localStorage.getItem(`localClickCount_${userId}`);
        const savedLocalCoinValue = localStorage.getItem(`localCoinValue_${userId}`);
        if (savedLocalClickCount && savedLocalCoinValue) {
          setLocalClickCount(parseInt(savedLocalClickCount, 10));
          setLocalCoinValue(parseInt(savedLocalCoinValue, 10));
          
        }
      }
    }, [userId]);
    
   
    const fetchAllDocuments = async () => {
      try {
          // setLoading(true);
          const allDocuments = await appwriteService.getAllDocuments();
          setDocuments(allDocuments);
          return allDocuments;
      } catch (error) {
          console.error('Error fetching all documents:', error);
         // setMessage({ type: 'error', text: 'Error fetching all documents.' });
      } finally{
          //  setLoading(false);
      }
  };

  
  const calculateRank = (weeklyClickCount) => {
    const sortedDocuments = documents.sort((a, b) => b.weeklyClickCount - a.weeklyClickCount);
    return sortedDocuments.findIndex(doc => doc.weeklyClickCount <= weeklyClickCount) + 1;
  };
  const userRank = calculateRank(weeklyClickCount);


  const handleClick = async () => {

    try {
     // await clicksAppwriteService.registerClick(userId);
      
      // setStatus('Click registered successfully!');
    } catch (error) {
      setStatus('Failed to register click.');
      //console.error(error);
    }
  };
 
  const shareStory = () => {
    try {
      const mediaUrl = 'https://cloud.appwrite.io/v1/storage/buckets/66c0b25b000872a841b7/files/66c0b28a002f60c59e82/view?project=66af5ec5001608e38606&project=66af5ec5001608e38606&mode=admin';
  
      // Ensure Telegram WebApp is ready
      const tg = window.Telegram?.WebApp;
  
      // Check if the method is available
      if (tg && typeof tg.shareToStory === 'function') {
        // Share the story with parameters
        tg.shareToStory(mediaUrl, {
          is_private: false, // Set to true if you want the story to be private
          caption: 'Asbeks Waliin Fuulduratti!', // Add a caption to the story
          background_color: '#FFFFFF' // Optional background color
        });
  
        //console.log('Story shared successfully!');
      } else {
        console.error('shareToStory is not available in this WebApp version.');
      }
    } catch (error) {
      console.error('Error sharing story:', error);
    }
  };
  
  useEffect(() => {
  
    const tg = window.Telegram.WebApp;
    tg.ready();

    const initDataUnsafe = tg.initDataUnsafe;
    const user = initDataUnsafe?.user;

    if (user) {
      
      const storedCoinValue = localStorage.getItem(`coinValue_${userId}`);
  if (storedCoinValue !== null) {
    setCoinValue(parseInt(storedCoinValue, 10));
    setIsFetchingCoinValue(false);
  }
    
          localStorage.setItem(`coinValue_${userId}`, theCoinValue);
            setIsFetchingCoinValue(false);
                  ////console.log('lastReset:', lastReset);

            const today = String(Math.floor(new Date().getTime() / 1000)); // Current Unix timestamp as a string
            ////console.log('today:', today);

            // Convert lastReset to seconds if it's in milliseconds
            const lastResetSeconds = theLastReset && theLastReset.length > 10 
            ? Math.floor(parseInt(theLastReset) / 1000) 
            : theLastReset || 0;
        
            // Convert timestamps to Date objects
            const lastResetDate = new Date(lastResetSeconds * 1000);
            const todayDate = new Date(today * 1000);

            // Check if they are on the same day
            const isSameDay = lastResetDate.toDateString() === todayDate.toDateString();

            ////console.log('Is today and last reset on the same day?', isSameDay);

            // Reset click count if last reset date is not today
            
            if (!isSameDay) {
              setErgaa('anbesssaa')
              ////console.log('anbesa:'); 
              setClickCount(0);
              appwriteService.updateDocument(userId, { clickCount: 0, lastReset: today });
              resetClickCount()
              ////console.log('gobez:'); 
            } else {
              setErgaa(theClickCount)
              setClickCount(theClickCount);
              ////console.log('else:', currentClickCount); 
            }

          } else {
            //console.error('Error: User document not found');
          }
    
  }, []);
  
  
  const lifeGiven = () => {
    if (isOnline){
      setBonusCount((prevCount) => prevCount + 1);
    if (theClickCount >= 1000000) {
      const today = String(Math.floor(new Date().getTime()));
      
      appwriteService.updateDocument(userId, { lastReset: today });
      setLimitReached(true);
      return;
    }else{
      setCountdown(30);
    }
  } else {
   //alert('You are offline. MineCoin actions are disabled.');
  }
  };

  const mineCoin = () => {
    if (isOnline){
    if (countdown === 0) {
      handleClick();
      setShowResumeButton(false);
  
      // incrementCoinValue(10)
      // incrementWeeklyClickCount(1)
      // incrementClickCount(1)
      // incrementDailyClickCount(1)
      // incrementMonthlyClickCount(1)
      setLocalClickCount((prevCount) => {
        const newClickCount = prevCount + 1;
        localStorage.setItem(`localClickCount_${userId}`, newClickCount); // Save to localStorage on each click
        return newClickCount;
      });

      setLocalCoinValue((prevCount) => {
        
        const newCoinValue = prevCount + 10;
        localStorage.setItem(`localCoinValue_${userId}`, newCoinValue);
        return newCoinValue;
      });

    }
  } else {
   //alert('You are offline. MineCoin actions are disabled.');
  }

  };

  const syncWithServer = async () => {
    setshowClaimButton(false)
    const savedLocalClickCount = localStorage.getItem(`localClickCount_${userId}`);
    const savedCoinValue = localStorage.getItem(`localCoinValue_${userId}`);
    const lastServerUpdate = localStorage.getItem('lastServerUpdate');
    //console.log('Saved Local Click Count:', savedLocalClickCount);
        //console.log('Saved Coin Value:', savedCoinValue);
        //console.log('Last Server Update:', lastServerUpdate);
       

    if (savedLocalClickCount && savedCoinValue) {
        const clickCount = parseInt(savedLocalClickCount, 10);
        const coinValue = parseInt(savedCoinValue, 10);

        // Validate and update lastServerUpdate if necessary
        if (!lastServerUpdate || isNaN(lastServerUpdate)) {
          lastServerUpdate = Date.now(); // Set current time if invalid or missing
          localStorage.setItem('lastServerUpdate', lastServerUpdate);
      }
      
        const savedClickTimestamp = parseInt(lastServerUpdate, 10);

        const competitionStart = await appwriteService.fetchCompetitionStartDate();

        if (competitionStart === 'ERROR_FETCHING_COMPETITION_START_DATE') {
          setShowError(true)
    
            // Hide the error after a short delay (e.g., 2 seconds)
            setTimeout(() => {
                setShowError(false)
            }, 2000);  // 2000ms = 2 seconds
                            // Handle the error scenario here, maybe retry or show a message
          return; // Exit early or retry
      }



      if (competitionStart.competitionStartDate == null){
        //console.log('no competition start.');
         if (clickCount > 0 && coinValue > 0) {
           // Sync the coins to the server
           await saveCoinsToServer(clickCount, coinValue);
       } 

       } 
        const competitionStartTime =  Date.parse(competitionStart.competitionStartDate);

        
        ////console.log('Click Count:', clickCount);
            // //console.log('Coin Value:', coinValue);
            // //console.log('Saved Click Timestamp:', savedClickTimestamp);
            // //console.log('Competition Start Time:', competitionStartTime);
            // //console.log('the parsiii:', Date.parse(competitionStart.competitionStartDate));
            // //console.log('the kanaaaa:', competitionStart.competitionStartDate);

          if(competitionStartTime){
            if (savedClickTimestamp >= competitionStartTime) {
              //console.log('Data is during or after competition.');
              if (clickCount > 0 && coinValue > 0) {
                // Sync the coins to the server
                await saveCoinsToServer(clickCount, coinValue);
            } 
    
            } 
            
            if (savedClickTimestamp < competitionStartTime) {
              //console.log('Data is before competition start.');
              if (clickCount > 0 && coinValue > 0) {
                // Sync the coins to the server
                await saveCoinsToServer(clickCount, coinValue);
            } 
            }
          }
        
        
    }
};

useEffect(() => {
  async function checkAndReset() {
    try {
      const pendingTransactionKey = `pendingTransaction_${userId}`;
      const pendingTransaction = JSON.parse(localStorage.getItem(pendingTransactionKey));

      const userDoc = await appwriteService.getDocument(userId);

      // Check if this transaction was already processed
      if (userDoc.lastTransactionId === pendingTransaction?.transactionId) {
        // Clear local values if server already processed this transaction
        localStorage.removeItem(pendingTransactionKey);
        localStorage.removeItem(`localClickCount_${userId}`);
        localStorage.removeItem(`localCoinValue_${userId}`);
        setLocalClickCount(0);
        setLocalCoinValue(0);
      }
    } catch (error) {
      console.error("Error checking transaction:", error);
    }
  }

  // Call the async function inside useEffect
  
    checkAndReset();
  
}, [userId, appwriteService]);

const saveCoinsToServer = async (clicks, coinValue) => {
  try {
    setshowClaimButton(false);

    // 1. Check for existing pending transaction (from a previous interrupted attempt)
    const pendingTransactionKey = `pendingTransaction_${userId}`;
    const pendingTransaction = JSON.parse(localStorage.getItem(pendingTransactionKey));
    
    // If a pending transaction exists, use its values instead of new ones
    if (pendingTransaction) {
      clicks = pendingTransaction.clicks;
      coinValue = pendingTransaction.coinValue;
    } else {
      // Generate new transaction ID and store pending data
      const transactionId = `${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      localStorage.setItem(pendingTransactionKey, JSON.stringify({
        transactionId,
        clicks: parseInt(clicks),
        coinValue: parseFloat(coinValue),
      }));
    }

    // 2. Atomic update with transaction validation
    const userDoc = await appwriteService.getDocument(userId);
    
    // Check if this transaction was already processed
    if (userDoc.lastTransactionId === pendingTransaction?.transactionId) {
      // Clear local values if server already processed this transaction
      localStorage.removeItem(pendingTransactionKey);
      localStorage.removeItem(`localClickCount_${userId}`);
      localStorage.removeItem(`localCoinValue_${userId}`);
      setLocalClickCount(0);
      setLocalCoinValue(0);
      return;
    }

    // 3. Calculate new values
    const newCoinValue = (parseFloat(userDoc.coinValue) || 0) + parseFloat(coinValue);
    const newLifeTimeClicks = (parseInt(userDoc.lifetimeClicks) || 0) + parseInt(clicks);
    const newWeeklyClickCount = (parseInt(userDoc.weeklyClickCount) || 0) + parseInt(clicks);
    const newDailyClickCount = (parseInt(userDoc.dailyClickCount) || 0) + parseInt(clicks);

    // 4. Update server with transaction ID
    const now = Math.floor(Date.now() / 1000);
    await appwriteService.updateDocument(userId, {
      online: String(now),
      coinValue: newCoinValue,
      lifetimeClicks: newLifeTimeClicks,
      weeklyClickCount: newWeeklyClickCount,
      dailyClickCount: newDailyClickCount,
      lastTransactionId: pendingTransaction.transactionId,
    });
    setshowClaimButton(false)
    incrementCoinValue(coinValue)
    incrementWeeklyClickCount(clicks)
    incrementClickCount(clicks)
    incrementDailyClickCount(clicks)
    incrementMonthlyClickCount(clicks)
    // 5. Only clear local values AFTER successful server confirmation
    localStorage.removeItem(pendingTransactionKey);
    localStorage.removeItem(`localClickCount_${userId}`);
    localStorage.removeItem(`localCoinValue_${userId}`);
    
    // 6. Update local state
    setLocalClickCount(0);
    setLocalCoinValue(0);
    setshowClaimButton(false);
    setStatus('Saved successfully');

  } catch (error) {
    // Keep pending transaction data for retry on failure
    console.error('Save failed:', error);
    setStatus('Save failed - will retry later');
  }
};



  // useEffect(() => {
  //   const interval = setInterval(syncWithServer, 30000); // Sync every 30 seconds
  //   return () => clearInterval(interval); // Cleanup on unmount
  // }, []);

  // useEffect(() => {
  //   // Sync coins with the server before closing the browser
  //   const handleBeforeUnload = (event) => {
  //     syncWithServer();
  //     // Optional: Standard beforeunload handling for confirmation
  //     event.preventDefault();
  //     event.returnValue = '';
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);
    
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  const resumeGame = async () => {
  if (countdown === 0) {
    handleClick();
    setShowResumeButton(false);

    
      try {
        // Use the user data from state instead of fetching again
        const now = Math.floor(Date.now() / 1000); // Current time in UNIX timestamp
        // await appwriteService.updateDocument(userId, { online: String(now) });

        // Calculate new values based on current state
        const newCoinValue = theCoinValue + 10;
        const newClickCount = theClickCount + 1;
        const newDailyClickCount = theDailyClickCount + 1;
        const newWeeklyClickCount = theWeeklyClickCount + 1;
        const newMonthlyClickCount = theMonthlyClickCount + 1;

        
        localStorage.setItem(`coinValue_${userId}`, newCoinValue);

        // Update the user document with new values
        await appwriteService.updateDocument(userId, {
          online: String(now),
          coinValue: newCoinValue,
          clickCount: newClickCount,
          dailyClickCount: newDailyClickCount,
          weeklyClickCount: newWeeklyClickCount,
          monthlyClickCount: newMonthlyClickCount
        });

        incrementCoinValue()
        incrementWeeklyClickCount()
        incrementClickCount()
        incrementDailyClickCount()
        incrementMonthlyClickCount()
        // Update local state
       

      } catch (error) {
        console.error('Error updating user data:', error);
      }
    
  }
};

  // const resumeGame = async () => {
  //   if (countdown === 0) {
  //     handleClick();
  //     setShowResumeButton(false);
  //     if (user) {
  //       try {
  //       const userId = String(user.id).trim();
  //       ////console.log('User ID:', userId);
  //       const userDoc = await appwriteService.getDocument(userId);
  //       if (userDoc) {
  //         const now = Math.floor(Date.now() / 1000); // Current time in UNIX timestamp

  //         appwriteService.updateDocument(userId, { online: String(now)})
  //         // Retrieve the most recent values from Appwrite
  //         const currentCoinValue = parseInt(userDoc.coinValue) || 0;
  //         const currentClickCount = parseInt(userDoc.clickCount) || 0;
  //         const currentDailyClickCount = parseInt(userDoc.dailyClickCount) || 0;
  //         const currentWeeklyClickCount = parseInt(userDoc.weeklyClickCount) || 0;
  //         const currentMonthlyClickCount = parseInt(userDoc.monthlyClickCount) || 0;


  //       //  // const currentCoinValue = coinValue;
  //       //   const newCoinValue = currentCoinValue + 10;
  //       //   const newclickCount = clickCount + 1;
  //       // Calculate new values
  //       const newCoinValue = currentCoinValue + 10;
  //       const newClickCount = currentClickCount + 1;
  //       const newDailyClickCount = currentDailyClickCount + 1;
  //       const newWeeklyClickCount = currentWeeklyClickCount + 1;
  //       const newMonthlyClickCount = currentMonthlyClickCount + 1;
  //         setCoinValue(newCoinValue);
  //         localStorage.setItem(`coinValue_${userId}`, newCoinValue);
  //         // await appwriteService.updateDocument(userId, {
  //         //    coinValue: newCoinValue,
  //         //    clickCount: newclickCount,
  //         //    dailyClickCount: dailyClickCount + 1,
  //         //    weeklyClickCount: weeklyClickCount + 1,
  //         //    monthlyClickCount: monthlyClickCount + 1
  //         //   });
  //         await appwriteService.updateDocument(userId, {
  //           coinValue: newCoinValue,
  //           clickCount: newClickCount,
  //           dailyClickCount: newDailyClickCount,
  //           weeklyClickCount: newWeeklyClickCount,
  //           monthlyClickCount: newMonthlyClickCount
  //         });
  //         ////console.log('Updated document:', updatedDoc); // Log updated document
  //          // Update local storage
  //          // Update state values
  //          setClickCount(newClickCount);
  //          setDailyClickCount(newDailyClickCount);
  //          setWeeklyClickCount(newWeeklyClickCount);
  //          setMonthlyClickCount(newMonthlyClickCount);
  //         // setClickCount(clickCount + 1);
  //         // setDailyClickCount(dailyClickCount + 1);
  //         // setWeeklyClickCount(weeklyClickCount + 1);
  //         // setMonthlyClickCount(monthlyClickCount + 1);
  //       } else {
  //         //console.error('Error: User document not found');
  //       }

  //       } catch (error) {
  //         //console.error('Error marking task as completed:', error);
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    if (countdown > 0) {
      const countdownInterval = setInterval(() => {
        setShowResumeButton(true);
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
      return () => clearInterval(countdownInterval);
    }
  }, [countdown]);
  const closeModal = () => {
    setLimitReached(false);
  };
  const left = 1000000 - theClickCount
  return (
    <EarnContainer>
      <div>
      
      {/* 
{competitionStartDate ? (
  <center>
    <p>Time since last synced: {formatTime(timeSinceLastUpdate)}</p>
    <p>{timeLeft}</p>
    <p>welcome from {country}</p>
  </center>
) : (
  <center>
    <p>Competition start date is not set.</p>
  </center>
)}
*/}

      <center>
        {/* <p>Time since last claimed: {formatTime(timeSinceLastUpdate)}</p> */}
        {/* <p>{timeLeft}</p> */}
      </center>
      {/* <p>{competitionStartDate}</p>
        <p>Ergaa: {ergaa}</p>
      <p>User ID: {theUserId}</p>
      <p>Status: {status}</p>
      <h3>Local Click Count: {localClickCount}</h3>
      <h3>Local Coin Value: {localCoinValue}</h3>
      <button onClick={mineCoin}>Mine Coin</button>  */}
      
</div>

       {/* <h>{ergaa}</h> */}
      <RowContainer>
            <WhiteCard>
                <RowContainer>
                    <RowLabel>Your Asbeks</RowLabel>
                </RowContainer>
                <RowContainer>
                {theCoinValue !== null ? (
                        <RowValue>{formatValue(theCoinValue)}</RowValue>
                    ) : (
                        <Skeleton />
                    )}
                </RowContainer>
            </WhiteCard>

            <WhiteCard onClick={handleTap}>
                <RowContainer>
                    <RowLabel>Balance</RowLabel>
                </RowContainer>
                <RowContainer>
                {(theasbeksPrice !== null && theasbeksPrice !== undefined &&
                  theCoinValue !== null && theCoinValue !== undefined &&
                  !isNaN(Number(theCoinValue)) && !isNaN(Number(theasbeksPrice))) ? (
                      <RowValue>
                          ${formatValue(Number(theCoinValue) * Number(theasbeksPrice))}
                      </RowValue>
                  ) : (
                      <Skeleton />
                  )}

                </RowContainer>
            </WhiteCard>
            
        </RowContainer>
     
      <RowContainer>

      <WhiteCard>
                <RowContainer>
                    <RowLabel>Your Reward Clicks</RowLabel>
                </RowContainer>
                <RowContainer>
                {theWeeklyClickCount !== null ? (
                        <RowValue>{theWeeklyClickCount}</RowValue>
                    ) : (
                        <Skeleton />
                    )}
                </RowContainer>
            </WhiteCard>

            <WhiteCard>
                <RowContainer>
                    <RowLabel>Your Reward Rank</RowLabel>
                </RowContainer>
                <RowContainer>
                    {theRank !== null ? (
                        <RowValue >{theRank}  </RowValue>
                    ) : (
                      <Skeleton />
                    )} 

                </RowContainer>
            </WhiteCard>
      </RowContainer>
      <WhiteFriendsCard fullWidth={true}>
    <RowContainer>
        <RowLabel>
            You have {!loadingFriends ? friendsCount : <Skeleton />} Friends
        </RowLabel>
    </RowContainer>
</WhiteFriendsCard>
{/* <InfoText>Daily Top users can withdraw daily. Yours: {dailyWithdraw ?? 0}</InfoText> */}



<DailyCard fullWidth={true}>
  <StatsContainer>
    {theDailyClickCount !== null ? (
      <h3>{theDailyClickCount}</h3>
    ) : (
      <Skeleton />
    )}
    <p>Daily Clicks</p>
  </StatsContainer>
  
  <StatsContainer style={{ flexDirection: 'column', justifyContent: 'center' }}>
    <p
      style={{
        textAlign: 'center',
        fontSize: '18px',
        fontFamily: 'Arial, sans-serif',
        color: '#333',
        fontWeight: 'normal',
        padding: '8px 0',
        margin: '0',
      }}
    >
      🎟️ {dailyWithdraw !== null || dailyRank !== null
  ? (dailyWithdraw !== null ? dailyWithdraw : 0)
  : <Skeleton />}


    </p>
  </StatsContainer>
  
  <StatsContainer>
    {dailyRank !== null ? (
      <h3>{dailyRank}</h3>
    ) : (
      <Skeleton />
    )}
    <p>Daily Rank</p>
  </StatsContainer>
</DailyCard>

    
      {/* {country === 'ET' && vpn && (
  <BeautifulBanner>
    <p>Turn on your VPN to access the content. This banner will stay until your country is not Ethiopia.</p>
  </BeautifulBanner>
)} */}
{/* <GiveLifeButton 
  onClick={(e) => { e.stopPropagation(); lifeGiven(); }} 
  disabled={(vpn && country === 'ET') || theWeeklyClickCount === null || isFetchingCoinValue} // Disable if VPN is on and banner is shown, or other conditions
>
  {(vpn && country === 'ET') 
    ? 'Use VPN' // Show "Use VPN" if both vpn and showBanner are true
    : theWeeklyClickCount === null && !isOnline 
      ? 'Waiting for network...' 
      : 'EARN ASBEK'}
</GiveLifeButton> */}
<AdsComponent blockId='7820' />
      {/* <GiveLifeButton 
      onClick={(e) => { e.stopPropagation(); lifeGiven(); }} 
     //disabled={theWeeklyClickCount === null || isFetchingCoinValue } // Disable button if waiting for network or fetching coin value
    >
    {theWeeklyClickCount === null && !isOnline ? 'Waiting for network...' : 'EARN ASBEKS +70'} 
     
    </GiveLifeButton> */}
    {/* <p>Bonus count: {bonusCount}</p> */}
   
    {/* <GiveLifeButton 
      onClick={(e) => { e.stopPropagation(); showAd(); }} 
      disabled={theWeeklyClickCount === null ||!adShown || isFetchingCoinValue  } // Disable button if waiting for network or fetching coin value
    >
      {theWeeklyClickCount === null && !isOnline ? 'Waiting for network...' : 'Bonus +50'}
    </GiveLifeButton> */}
<GiveLifeButton 
  onClick={() => {
    if (showClaimButton && !isProcessing.current) { // Ensure it's clickable and not processing
      handleClaim();
    }
  }} 
  disabled={isProcessing.current||theCoinValue === null||!showClaimButton  || !localClickCount  } // Proper check for button disable condition
>
  {/* {theWeeklyClickCount === null && !isOnline 
    ? 'Waiting for network...' 
    : `CLAIM RC: ${localClickCount} ASBEKS: ${localCoinValue}`
  } */}
  {isProcessing.current 
    ? 'Processing...'  // Show "Processing..." during sync
    : theWeeklyClickCount === null && !isOnline 
      ? 'Waiting for network...' 
      : `CLAIM RC: ${localClickCount} ASBEKS: ${localCoinValue}`
  }
</GiveLifeButton>
{showError && (
  <p style={{
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#ff4c4c',
    color: 'white',
    padding: '20px 30px',
    borderRadius: '8px',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
    zIndex: 9999,
    maxWidth: '400px',
    width: '100%',
    animation: 'fadeIn 0.5s ease-out',
  }}>
    Claim Later
  </p>
)}

<style>
  {`
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `}
</style>

<AsbeksPrice/>
{/* <GiveLifeButton 
  onClick={(e) => { e.stopPropagation(); shareStory(); }} 
  // Disable button if waiting for network or fetching coin value
>
  {theWeeklyClickCount === null ? 'Waiting for network...' : 'SHARE STORY'}
</GiveLifeButton> */}

{isFetchingCoinValue && <FetchingText>Loading...</FetchingText>}


     
      {limitReached && (
        <Modal>
          <ModalContent>
            <CloseButton onClick={closeModal}>X</CloseButton>
            <p>You have reached the maximum number of clicks for today.</p>
          </ModalContent>
        </Modal>
      )}
      {/* {showResumeButton && (
        <AdsComponent blockId='4120' />
        // <Modal>
        //   <ModalContent>
        //     <CloseButton onClick={mineCoin}>
        //       {countdown > 0 ? countdown : 'Done'}
        //     </CloseButton>
        //     <AdsComponent blockId='4120' />
        //     <IframeContainer>
        //       <iframe
        //         title="External Content"
        //         src={''}
        //       />
        //     </IframeContainer>
        //   </ModalContent>
        // </Modal>
      )} */}
      {/* <WeeklyPage/> */}
  {/* <TopClicksPage/> */}
      {/* <TopClicksOfToday/> */}
      {/* <TopUsers/> */}
      {/* Password input dialog */}
      {showPasswordDialog && (
        <Modal>
          <ModalContent>
            <h3>Enter Password</h3>
            <input
              type="password"
              value={passwordInput}
              onChange={(e) => setPasswordInput(e.target.value)}
              placeholder="Enter password"
            />
            <button onClick={handlePasswordSubmit}>Submit</button>
            <CloseButton onClick={() => setShowPasswordDialog(false)}>Cancel</CloseButton>
          </ModalContent>
        </Modal>
      )}
     {showPasswordDialog && (
        <Modal>
          <ModalContent>
            <h3>Enter Password</h3>
            <input
              type="password"
              value={passwordInput}
              onChange={(e) => setPasswordInput(e.target.value)}
              placeholder="Enter password"
            />
            <button onClick={handlePasswordSubmit}>Submit</button>
            <CloseButton onClick={() => setShowPasswordDialog(false)}>Cancel</CloseButton>
          </ModalContent>
        </Modal>
      )}

      {/* Bottom sheet */}
      {showBottomSheet && (
  <div style={bottomSheetStyles}>
    <button onClick={handleCloseBottomSheet} style={closeButtonStyles}>Close</button>
    <SomeComponent />
  </div>
)}

    </EarnContainer>
  );
}

// Styles
const contentContainerStyles = {
  paddingBottom: '60px', // Space for the bottom sheet
  overflowY: 'auto', // Ensure content is scrollable
  height: '100vh', // Full viewport height
};

const bottomSheetStyles = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#fff',
  boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.2)',
  padding: '20px',
  maxHeight: '60%',
  overflowY: 'auto',
  zIndex: 1000,
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(0)', // Ensure it's visible
};

const toggleButtonStyles = {
  position: 'fixed',
  bottom: '70px', // Adjust based on the height of the bottom sheet
  left: '20px',
  zIndex: 1001,
};

const closeButtonStyles = {
  marginLeft: 'auto',
  background: 'none',
  border: 'none',
  fontSize: '16px',
};

export default EarnSection;